<template>
  <b-card>
    <user-profile-avatar :display_name="fullName()" :source="userFormData.avatar"/>

    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.first_name')" label-for="account-username">
            <b-form-input v-model="userFormData.firstName" name="firstName" placeholder="John"/>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.last_name')" label-for="account-username">
            <b-form-input v-model="userFormData.lastName" name="lastName" placeholder="Doe"/>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.email')" label-for="account-e-mail">
            <b-form-input v-model="userFormData.email" name="email" placeholder="Email"/>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.phone_number')" label-for="account-name">
            <b-form-input v-model="userFormData.phoneNumber" v-mask="'(###) ###-####'" name="phoneNumber" placeholder="4181234567"/>
          </b-form-group>
        </b-col>
        <b-col v-if="pcVoyagesEnabled" sm="6">
          <b-form-group :label="$t('user_profile.pcvoyages_agent_code')" label-for="agent-code">
            <b-form-input v-model="userFormData.pcVoyagesAgentCode" name="pcVoyagesAgentCode" placeholder="ABC"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button :disabled="canViewBasedOnRole(currentAuthUser, [Roles.AGENCY_AGENT.role])"  @click.prevent="updateProfile" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="primary">
            {{ $t('button.save') }}
          </b-button>

          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mt-2"
              type="reset"
              variant="outline-secondary"
              @click.prevent="resetForm"
          >
            {{ $t('button.reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import UserProfileAvatar from '@/modules/users/components/UserProfileAvatar';
import { useUserApi } from '@/modules/users/composables/use-user-api';
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { onMounted, reactive, ref } from '@vue/composition-api';
import { BAlert, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BLink, BRow } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import Roles from "@/modules/authnz/models/role";
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import {groupReservationService} from "@/views/organisation/reservations/Reservations";

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BLink,
    BRow,
    UserProfileAvatar,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      Roles,
      currentAuthUser: null,
      pcVoyagesEnabled: false
    }
  },
  created() {
    this.currentAuthUser = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`].role;
    this.pcVoyagesEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;
  },
  setup() {
    const { logger, route } = useApplicationContext();
    const { getUserProfile, updateUserProfile } = useUserApi();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();

    let loadedUserData = reactive({});
    let userFormData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      avatar: '',
      pcVoyagesAgentCode: ''
    });

    let profileFile = ref(null);
    const refInputEl = ref(null);
    const previewEl = ref(null);

    onMounted(async () => {
      try {
        let userId = route.value.params.id;
        let user = await getUserProfile(userId);

        loadedUserData = reactive(user);
        Object.assign(userFormData, user);
      } catch (error) {
        logger.error(error);
      }
    });

    const updateProfile = async () => {
      try{
        let userId = route.value.params.id;
        await updateUserProfile(userId, userFormData);
        displaySuccessMessage('Les changements ont été sauvegardés avec succès');
      } catch (e) {
        console.error(e)
        displayErrorMessage('Une erreur est survenue en essayant de sauvegarder les changements');
      }


    }

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    const resetForm = () => Object.assign(userFormData, loadedUserData);
    const fullName = () => `${userFormData.firstName} ${userFormData.lastName}`;

    return {
      refInputEl,
      previewEl,
      profileFile,
      userFormData,
      updateProfile,

      inputImageRenderer,
      resetForm,
      fullName,
    };
  },
  methods: {
    canViewBasedOnRole(currentRole, allowedRoles) {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles)
    },
  }
};
</script>
